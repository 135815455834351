html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
}

